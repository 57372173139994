import './App.css';
import Testimonials from './Testimonials';

function App() {
  return (
    <div className="bg-blue-300 p-[4rem]">
    <Testimonials />
  </div>
  );
}

export default App;
